import React from 'react';
import './Partner.scss';


export const PartnerItemComponent = ({ partner }) => {
    return (
        <div className="list-item">
            <div className="icon">
                <div className="imagem-circular">
                    <img className='imagem-circular-logo' src={partner.logo != null ? partner.logo : "images/sem_foto.png"} alt={partner.name} />
                </div>

            </div>
            <div className="text">
                <div className="title">{partner.name}</div>
                <div className="subtitle">{partner.address.city}-{partner.address.state}</div>
            </div>
        </div>
    );
};