export function formatReal(number) {

    if (Number.isNaN(number) || !number) return `R$ 0,00`;

    if (typeof number === "string") { // n1
        number = Number(number);
    }

    let res;
    const config = { style: 'currency', currency: 'BRL' };

    res = number.toLocaleString('pt-BR', config)

    const needComma = number => number <= 1000;
    if (needComma(number)) {
        res = res.toString().replace(".", ",");
    }
    return res;
}