import React, { useEffect, useState } from 'react';
import { PartnerItemComponent } from './PartnerItemComponent';
import unorm from 'unorm';
import { LoadingComponent } from '../../components/loading/LoadingComponent';
import axios from 'axios';
import { EnviromentConst } from '../../enviroment';
import { Link } from "react-router-dom";

import './Partner.scss';


export const PartnersPage = () => {

    const [partners, setPartners] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(true);

    const handleSearchChange = (event) => {
        setSearchTerm(unorm.nfkd(event.target.value).replace(/[\u0300-\u036F]/g, ''));
    };

    const normalizedSearchTerm = unorm.nfkd(searchTerm).replace(/[\u0300-\u036F]/g, '');

    const filteredPartners = partners.filter(item => {
        const normalizedItemName = unorm.nfkd(item.name).replace(/[\u0300-\u036F]/g, '');
        const normalizedCity = unorm.nfkd(item.address.city).replace(/[\u0300-\u036F]/g, '');

        return normalizedItemName.toLowerCase().includes(normalizedSearchTerm.toLowerCase())
            || normalizedCity.toLowerCase().includes(normalizedSearchTerm.toLowerCase());
    });


    useEffect(() => {
        const fetchData = async () => {
            try {
                const resposta = await axios.get(`${EnviromentConst.API_URL}/v1/companies/partners`);
                setPartners(resposta.data);
            } catch (error) {
                setPartners([])
            } finally {
                setLoading(false)
            }
        };

        fetchData();
    }, []);


    return (
        <div>
            <div className='header-serach-partner'>
                <div className="d-flex justify-content-center container">
                    <div style={{ float: 'center' }} className='col-md-12'>
                        <input type="text"
                            className="form-control"
                            placeholder='Buscar'
                            value={searchTerm}
                            onChange={handleSearchChange} />
                    </div>
                </div>
            </div>



            <div className='container'>
                <div className="list-container">
                    {
                        isLoading ?
                            <div className="d-flex justify-content-center container">
                                <LoadingComponent />
                            </div> :
                            filteredPartners.map(item => (
                                <Link style={{ textDecoration: 'none' }} to={item.nickname} key={item.id} state={{partnerData: item}}>
                                    <PartnerItemComponent partner={item} />
                                </Link>
                            ))
                    }
                </div>
            </div>
        </div>

    );
};