import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { formatReal } from '../../util/util';
import { EnviromentConst } from '../../enviroment';
import axios from 'axios';
import Sheet from 'react-modal-sheet';


import './Partner.scss';


export const PartnerDetailPage = () => {
    const location = useLocation();
    const { partnerData } = location.state;
    const [products, setProducts] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(false);

    const openModal = (product) => {
        setCurrentProduct(product)
        setOpen(true)
    };

    const renderDescription = (title, value) => {
        return <div className='row'>
             <hr></hr>
            <div className='col' style={{ textAlign: 'left', marginLeft: '10px' }}>
                <b>{title}</b>
            </div>
            <div className='col' style={{ textAlign: 'right', marginRight: '10px' }}>
                <p>{value}</p>
            </div>
            
        </div>
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                const resposta = await axios.get(`${EnviromentConst.API_URL}/v1/companies/partners/${25}/products`);
                setProducts(resposta.data);
            } catch (error) {
                setProducts([])
            } finally {
                // setLoading(false)
            }
        };

        fetchData();
    }, []);


    const openMaps = () => {
        const address = partnerData?.address
        if (address === null || address === undefined) return;
        const fullAddress = `${address.street}, ${address.number}, ${address.district}`
        const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`;
        window.open(url, '_blank');
    };

    return (
        <div>
            <div className='partner-header'>

                <div className='partner-info'>
                    <h2>{partnerData?.name}</h2>
                    <h6>{partnerData?.address?.street}, {partnerData?.address?.number}</h6>
                    <h6>{partnerData?.address?.district}</h6>
                    <h6>{partnerData?.address?.zipcode} - {partnerData?.address?.city}/{partnerData?.address?.state}</h6>
                    <button type="button" className="btn btn-link" onClick={() => openMaps()}>Abrir no mapa</button>
                </div>

            </div>

            <div>
                {
                    products.map(item => (
                        <div onClick={() => openModal(item)}>
                            <div className="list-item">
                                <div className="icon">
                                    <div className="imagem-circular">
                                        <img className='logo-product' src="images/cup.png" alt='img-product' />
                                    </div>

                                </div>
                                <div className="text">
                                    <div className="title">{item.productDescription}</div>
                                    <div className="subtitle">{item.family} / {item.style}</div>
                                </div>


                                <div className="arrow">
                                    <div className="title amount">{formatReal(item.price)}/L</div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
                    <Sheet.Container>
                        <Sheet.Header />
                        <Sheet.Content>
                            <div className='d-flex justify-content-center'>
                                <h1><span class="badge bg-secondary">{currentProduct?.monitorTag}</span></h1>
                                <hr></hr>
                            </div>
                            <div style={{marginTop: '50px'}}>
                                {renderDescription('Descrição', currentProduct?.productDescription)}
                                {renderDescription('Familia', currentProduct?.family)}
                                {renderDescription('Conteúdo alcoólico', currentProduct?.alcoholContent)}
                                {renderDescription('Amargura', currentProduct?.bitterness)}
                                {renderDescription('Cor', currentProduct?.color)}
                                {renderDescription('Temperatura', currentProduct?.temperature)}
                                {renderDescription('Monitor', currentProduct?.monitorTag)}
                                {renderDescription('Torneira', currentProduct?.tapTag)}
                                {renderDescription('Preço /L', formatReal(currentProduct?.price))}
                            </div>
                        </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop />
                </Sheet>
            </div>
        </div>

    );
};