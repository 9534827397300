import { Routes, Route } from "react-router-dom";
import { PartnersPage } from "./pages/partners/PartnersPage";
import { PartnerDetailPage } from "./pages/partners/PartnerDetailPage";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PartnersPage/>} />
      <Route path="/:nickname" element={<PartnerDetailPage/>} />
    </Routes>
  );
};