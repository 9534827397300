import './App.css';
import { AppRoutes } from './routes';

function App() {
  return (
    <div>
      <AppRoutes></AppRoutes>
    </div>
  );
}

export default App;
