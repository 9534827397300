import React from 'react';
import './Loading.scss';

export const LoadingComponent = ({ partner }) => {
    return (
        <div className="wrapper">
            <div className="progresso-circular"></div>
            <div className="aguarde">Aguarde...</div>
        </div>
    );
};